import React, { Component } from "react";
import { Link } from "react-router-dom";
import fashion from "../assets/img/dress.png";
import footwear from "../assets/img/footwear.png";
import fmcg from "../assets/img/fmcg.png";
import consumer from "../assets/img/consumer.png";
import stationery from "../assets/img/stationery.png";
import fitness from "../assets/img/fitness.png";
import globe from "../assets/img/globe.png";
import shopping from "../assets/img/shopping-cart.png";
import LebenswerkRetail from "../assets/img/advikaedutechretail.jpg";
import LebenswerkFmcg from "../assets/img/advikaedutechretail2.jpg";
import techsupport from "../assets/img/techsupport.jpg";
import Contact from "../assets/img/LebenswerkContact.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import AlliedServices from "../assets/img/LebenswerkAlliedServices.svg";
import Consulting from "../assets/img/LebenswerkConsulting.svg";
import EcomServices from "../assets/img/LebenswerkEcomServices.svg";
import HrServices from "../assets/img/LebenswerkHRservice.svg";
import ADVIKA_pdf1 from "../assets/img/ADVIKA_pdf1.pdf";
import ADVIKA_pdf2 from "../assets/img/ADVIKA_pdf2.pdf";
import ADVIKA_pdf3 from "../assets/img/ADVIKA_pdf3.pdf";
import furo from "../assets/img/furo.png";
import action from "../assets/img/action.png";
import champs from "../assets/img/champs.jpg";
import combit from "../assets/img/combit.png";
import provogue from "../assets/img/provogue.png";
import RedChief from "../assets/img/RedChief.png";
import liberty from "../assets/img/liberty.jpeg";
import bahamas from "../assets/img/bahamas.jpg";
import relaxo from "../assets/img/LebenswerkBrandrelaxo.png";
import sparx from "../assets/img/LebenswerkBrandSparx.png";
import lawman from "../assets/img/lawman.png";
import nike from "../assets/img/nike.png";
import fila from "../assets/img/fila.png";
import killer from "../assets/img/killerlogo.png";
import campus from "../assets/img/campus.jpg";
import aqualite from "../assets/img/aqualite.png";
import lotto from "../assets/img/lotto.png";
import flite from "../assets/img/flite.jpg";
import cadbury from "../assets/img/cadbury.png";
import brucoffeelogo from "../assets/img/brucoffeelogo.png";
import unilever from "../assets/img/unilever.jpg";
import pepsi from "../assets/img/pepsi.png";
import lipton from "../assets/img/lipton.png";
import tajmahal from "../assets/img/tajmahal.png";
import sugarfree from "../assets/img/sugarfree.png";
import everyouth from "../assets/img/everyouthh.jpg";
import Luxor from "../assets/img/Luxor.png";
import Parker from "../assets/img/Parker.png";
import waterman from "../assets/img/waterman.png";
import pilot from "../assets/img/pilot.png";
import vodafone from "../assets/img/vodafone.png";
import shell from "../assets/img/shell.png";
import valvoline from "../assets/img/valvoline.png";
import sale from "../assets/img/sale.png";
import cart from "../assets/img/carts.png";
import moderntrade from "../assets/img/moderntrade.png";
import largeformatretail from "../assets/img/largeformatretail.png";
import brand from "../assets/img/brand.png";
import wholesale from "../assets/img/wholesale.png";
import giftbox from "../assets/img/giftbox.png";
import domainImg from "../assets/img/domainImg.png";
import store from "../assets/img/store.png";
import dmart from "../assets/img/dmart.jpg";
import walmart from "../assets/img/walmart.png";
import bigbazaar from "../assets/img/bigbazaar.png";
import metroshoes from "../assets/img/metroshoes.png";
import metrocash2 from "../assets/img/metrocash2.png";
import itc from "../assets/img/itc.png";
import abrl from "../assets/img/abrl.svg";
import Saravana from "../assets/img/Saravana.png";
import vishal from "../assets/img/vishal.png";
import lifestyle from "../assets/img/lifestyle.png";
import styleisland from "../assets/img/styleisland.jpg";
import homeco from "../assets/img/homeco.png";
import draaxfashion from "../assets/img/draaxfashion.png";
import tatters from "../assets/img/tatters.jpg";
import devpharma from "../assets/img/devpharma.JPG";
import khwaabi from "../assets/img/khwaabi.jpg";
import metalsmith from "../assets/img/metalsmith.JPG";
import phoebee from "../assets/img/phoebee.JPG";
import gkhair from "../assets/img/gkhair.JPG";
import keune from "../assets/img/keune.JPG";
import phlogo from "../assets/img/phlogo.JPG";
import swissbeauty from "../assets/img/swissbeauty.JPG";
import tibolli from "../assets/img/tibolli.JPG";
import hilary from "../assets/img/hilary.JPG";

import SliderComp from "./SliderComp";
import Accordion from "./Accordion";

class Home extends Component {
  render() {
    const accordionData = [
      {
        title: "Are E-Commerce Solutions Important?",
        content:
          "Absolutely, E-commerce solutions are critical for any businesses who are aiming to reach an audience and stay competitive in the marketplace. At Advika Edutech, we understand the role of e-commerce which ensures your business's high success. Our solutions are designed in a way that your Online Presence improves customer experience and increases profitability. With our expertise in IT Infrastructure, application development and consulting we can provide e-commerce solutions.",
      },
      {
        title: "How Will You Promote My Online Business?",
        content:
          "Promoting Online business requires a comprehensive and strategic approach with expertise in IT consulting, Application Development, and IT Infrastructure, Advika Edutech provides multifaceted solutions to enhance your online presence and drive business growth. Out services like Customized Application Development, IT Infrastructure Optimization, Performance Marketing, Manpower Staffing, Skill Development/Training and It audits are essential to ensure your online business security and performance at its best. By Integrating these services you can reach your target audience and can build a strong brand.",
      },
    ];
    return (
      <>
        <header class="jumbotron video-container">
          {/* <video id="myVideo" class="" autoPlay muted loop >
                <source src={Headervideo} type="video/mp4" />
              
              </video>  */}

          {/* <div class="icon-bar">
            <a
              href="https://api.whatsapp.com/send?phone=919953559179"
              class=""
              role="button"
            >
              <FontAwesomeIcon icon={faWhatsapp} />
            </a>
          </div> */}

          <div class="container" id="bgimg">
            <div class="row row-header">
              <div class="col-12 col-sm-6 intro" id="reservebtn">
                {/* <h5>Our Experience in the Areas</h5> */}
                <h1>
                  <span>O N L I NE</span>&nbsp;&nbsp;
                  <br class="hide-hero1" />
                  <span>E X A M I N A T I O N S</span>&nbsp;&nbsp;
                </h1>
                <br />
                <br />

                <div class="css-typing">
                  <p>One Stop Solution</p>
                  <p>For all Online Examination Process.</p>
                </div>
                {/*    <a href="" class="typewrite" data-period="2000" data-type='[ "We Absorb Complexity." ]'>
                            <span class="wrap"></span>
                          </a>
                          <br/>
                         {/*  <a href="" class="typewrite" data-period="2000" data-type='[ "We Deliver Clarity." ]'>
                            <span class="wrap"></span>
                          </a> */}

                {/* <h3>Deliver Clarity.</h3> 
                     <p>LebensWerk provides Business Consulting, e-Commerce Business Solutions, and Human Resource Services in FMCG, fashion, footwear and all type of Consumer products with diversified areas of expertise
                            <span class="intro-p-span">  in Sales, Distribution, Business Development, Marketing, Supply Chain, Demand Planning, Business Analytics, Business Planning & Human Resource.</span>
                           
                           </p>  */}
                <br />

                <br />
                <a
                  href="https://forms.gle/gAD9rvjVtNkrSsXu5"
                  data-placement="bottom"
                  class="btn btn-lg "
                  role="button"
                >
                  Know more
                </a>
              </div>
              {/* <div class="col-12 col-sm-6">
                    <div class="mainhero">
                        <img src="img/store.jpg" class="img-fluid">
                    </div>
                 
                </div>    */}
            </div>
          </div>
          {/* <h6 className="text-center Aboutcomp">
            Lebenswerk Consulting is the Best E-Commerce Account Management
            Service Provider In Delhi NCR
          </h6> */}
        </header>
        <div class="container-fluid industry">
          <div class="row row-content main-intro-block">
            <div class="col-12  text-center">
              <h1 className="main-heading">
                &#10077; India's Leading Online Examination Service Provider
                &#10078;
              </h1>

              {/* <h5>
                As more and more sellers are switching to online stores,
                generating sales has become troublesome. But if you have a
                professional e-commerce business solutions provider by your
                side, you won’t face any hassles.
              </h5> */}

              <div class="industry-grid mt-3">
                <div class="grid_row service-offered">
                  <p class="mt-4">
                    We’re Advika Edutech a Leading Online Examination Service
                    Provider your premier destination for comprehensive Online
                    examination Solutions in India. As an ISO Certified company,
                    we offer you a wide range of services just tailored to meet
                    the needs of educational institutions, corporate entities,
                    and government organizations. Our Commitment to excellence
                    and innovation made us a trusted partner in the IT in
                    industry, delivering cutting-edge solutions to top IT
                    companies across the country.
                  </p>
                </div>

                <span>
                  <Link to="/ecommerce-business-management-company">
                    <a
                      href="#reserve"
                      data-toggle="modal"
                      data-html="true"
                      data-target="#reserveModal"
                      data-placement="bottom"
                      class="btn btn-lg "
                      role="button"
                    >
                      ➢ Explore More
                    </a>
                  </Link>
                </span>
              </div>
            </div>
          </div>

          {/* <div class="row row-content fitplans">
            <div class="col-12 text-center p-2" id="discount-block">
              <h1 className="main-heading">
                Best E-Commerce Business Solutions Provider In India To Maximize
                Your Sales Revenue
              </h1>
              <h5>
                As more and more sellers are switching to online stores,
                generating sales has become troublesome. But if you have a
                professional e-commerce business solutions provider by your
                side, you won’t face any hassles.
              </h5>

              <div class="col-12">
                <div class="industry-grid">
                  <p class="mt-4">
                    Lebenswerk Consulting is an E-Commerce Account Management
                    Service Provider in India catering to the diverse needs of
                    online sellers. We serve diverse industries including
                    Fashion, Lifestyle, Footwear, Stationery, FMCG, Retail,
                    Consumer, and Internet. Our team of experts comprises highly
                    qualified and experienced people from IMS, XLRI, NIIT, FMS,
                    and other authorized institutions.
                  </p>
                </div>
              </div>
            </div>

            <div class="col-sm-6 deals my-auto" id="reservebtn">
              <h3 class="">What We Do </h3>

              <p class="mt-4">
                Business Transformation | e-Commerce Management | Sales
                Structure Planning | Corporate Planning | Annual Business
                Planning | Supply Chain Set-up | Processes Implementation |
                Training &amp; Development | Employees KRA &amp; KPI Formulation
                | GTM Planning | Financial Assessment | Performance Marketing
              </p>

              <a
                href="#reserve"
                data-toggle="modal"
                data-html="true"
                data-target="#reserveModal"
                data-placement="bottom"
                class="btn btn-lg "
                role="button"
              >
                Channel Sales
              </a>
              <a
                href="#reserve"
                data-toggle="modal"
                data-html="true"
                data-target="#reserveModal"
                data-placement="bottom"
                class="btn btn-lg "
                role="button"
              >
                LFR
              </a>
              <a
                href="#reserve"
                data-toggle="modal"
                data-html="true"
                data-target="#reserveModal"
                data-placement="bottom"
                class="btn btn-lg "
                role="button"
              >
                E-commerce
              </a>
            </div>

            <div class="col-sm-6 p-2">
              <div class="row">
                <div class="col p-5">
                  {" "}
                  <img src={LebenswerkRetail} class="img-fluid" />
                </div>
              </div>
            </div>
            <br />

            <div class="col-sm-6 p-2">
              <div class="row d-none d-sm-block">
                <div class="col p-5">
                  {" "}
                  <img src={LebenswerkFmcg} class="img-fluid" />
                </div>
              </div>
            </div>
            <div class="col-sm-6 deals my-auto" id="reservebtn">
              <h3 class="">Who We Are </h3>

              <p class="mt-4">
                Lebenswerk's core team has vast experience into various consumer
                domains industries and worked across top-notch MNC &amp; Leading
                consumer companies in India. Academics background of our team
                are from XLRI - IMS - NIIT - FMS-ISS – Other Top Colleges,
                highly analytical and process oriented.
              </p>

              <a
                href="#reserve"
                data-toggle="modal"
                data-html="true"
                data-target="#reserveModal"
                data-placement="bottom"
                class="btn btn-lg "
                role="button"
              >
                XLRI
              </a>
              <a
                href="#reserve"
                data-toggle="modal"
                data-html="true"
                data-target="#reserveModal"
                data-placement="bottom"
                class="btn btn-lg "
                role="button"
              >
                IMS
              </a>
              <a
                href="#reserve"
                data-toggle="modal"
                data-html="true"
                data-target="#reserveModal"
                data-placement="bottom"
                class="btn btn-lg "
                role="button"
              >
                NIIT
              </a>
            </div>

            <div class="row  d-block d-sm-none">
              <div class="col p-5">
                <img src={LebenswerkFmcg} class="img-fluid" />
              </div>
            </div>
          </div> */}

          <div class="row row-content fitplans about-us-section">
            <div class="col-12 text-center p-2" id="discount-block">
              <h2>About Us</h2>
              <h5>Advika Edutech Transforms Your Business</h5>
            </div>

            <div class="col-sm-6 deals my-auto" id="reservebtn">
              <h3 class="">What We Do </h3>

              <p class="mt-4">
                We provide services like Online Examination Management where we
                design software to stream the entire examination process with
                monitoring and result analysis, we provide all solutions that
                ensure efficiency and accuracy. We offer manpower temporary
                staffing services, connecting businesses with highly skilled
                professionals for short-term projects and needs. Our manpower
                staffing company is dedicated to providing the best talent in
                the industry, ensuring that your organization can scale
                operations very easily.
              </p>

              <a
                href="#reserve"
                data-toggle="modal"
                data-html="true"
                data-target="#reserveModal"
                data-placement="bottom"
                class="btn btn-lg "
                role="button"
              >
                Channel Sales
              </a>
              <a
                href="#reserve"
                data-toggle="modal"
                data-html="true"
                data-target="#reserveModal"
                data-placement="bottom"
                class="btn btn-lg "
                role="button"
              >
                LFR
              </a>
              <a
                href="#reserve"
                data-toggle="modal"
                data-html="true"
                data-target="#reserveModal"
                data-placement="bottom"
                class="btn btn-lg "
                role="button"
              >
                E-commerce
              </a>
            </div>

            <div class="col-sm-6 p-2">
              <div class="row">
                <div class="col p-5">
                  {" "}
                  <img src={LebenswerkRetail} class="img-fluid" />
                </div>
              </div>
            </div>
            <br />

            <div class="col-sm-6 p-2">
              <div class="row d-none d-sm-block">
                <div class="col p-5">
                  {" "}
                  <img src={LebenswerkFmcg} class="img-fluid" />
                </div>
              </div>
            </div>
            <div class="col-sm-6 deals my-auto" id="reservebtn">
              <h3 class="">Who We Are </h3>

              <p class="mt-4">
                Advika Edutech is our pride as we are a forward-thinking company
                always committed to transforming to change across all
                industries. Our team is well-packed with professionals who bring
                great experience to leading multinational corporations and top
                companies in India with academic backgrounds from some of the
                most prestigious institutions such as XLRI, IMS, NIIT, FMS, and
                ISS. Our team is not just highly educated but also deeply
                analytical and oriented towards the process.
              </p>

              <a
                href="#reserve"
                data-toggle="modal"
                data-html="true"
                data-target="#reserveModal"
                data-placement="bottom"
                class="btn btn-lg "
                role="button"
              >
                XLRI
              </a>
              <a
                href="#reserve"
                data-toggle="modal"
                data-html="true"
                data-target="#reserveModal"
                data-placement="bottom"
                class="btn btn-lg "
                role="button"
              >
                IMS
              </a>
              <a
                href="#reserve"
                data-toggle="modal"
                data-html="true"
                data-target="#reserveModal"
                data-placement="bottom"
                class="btn btn-lg "
                role="button"
              >
                NIIT
              </a>
            </div>

            <div class="row  d-block d-sm-none">
              <div class="col p-5">
                {" "}
                <img src={LebenswerkFmcg} class="img-fluid" />
              </div>
            </div>
          </div>

          <div class="row row-content discount industry-exposure-section">
            <div class="col-12 text-center p-2" id="discount-block">
              <h2>Industry Exposure</h2>
              <h5>Our Experience in the Areas</h5>
            </div>

            <div class="col-12">
              <div class="industry-grid">
                <div class="grid_row">
                  <div class="grid_item">
                    <div class="grid_img">
                      <img src={fashion} class="img-fluid" />
                    </div>
                    <span class="grid_text">Business transformation</span>
                  </div>
                  <div class="grid_item">
                    <div class="grid_img">
                      <img src={footwear} class="img-fluid" />
                    </div>
                    <span class="grid_text">E-commerce management</span>
                  </div>
                  <div class="grid_item">
                    <div class="grid_img">
                      <img src={fmcg} class="img-fluid" />
                    </div>
                    <span class="grid_text">Sales Structure Planning</span>
                  </div>
                  <div class="grid_item">
                    <div class="grid_img">
                      <img src={consumer} class="img-fluid" />
                    </div>
                    <span class="grid_text">Corporate Planning</span>
                  </div>
                </div>
                <div class="grid_row">
                  <div class="grid_item">
                    <div class="grid_img">
                      <img src={stationery} class="img-fluid" />
                    </div>
                    <span class="grid_text">Annual Business Planning</span>
                  </div>
                  <div class="grid_item">
                    <div class="grid_img">
                      <img src={fitness} class="img-fluid" />
                    </div>
                    <span class="grid_text">Training & Development</span>
                  </div>
                  <div class="grid_item">
                    <div class="grid_img">
                      <img src={globe} class="img-fluid" />
                    </div>
                    <span class="grid_text">Go-To-Market Planning</span>
                  </div>
                  <div class="grid_item">
                    <div class="grid_img">
                      <img src={shopping} class="img-fluid" />
                    </div>
                    <span class="grid_text">Channel Sales and LFR</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div class="row row-content clientNum">
            <div class="projectnum wow fadeInUp" data-wow-duration=".5s">
              <div class="main-total-box anim-counter">
                <div>
                  <strong class="newde">135,014</strong>
                  <span data-content="home.stat.1">
                    Succesfull ad campaigns
                  </span>
                </div>
                <div>
                  <strong>3000 cr</strong>
                  <span data-content="home.stat.2">
                    Annual Turnover Managment
                  </span>
                </div>
                <div>
                  <strong>1050</strong>
                  <span data-content="home.stat.3">Employees Management</span>
                </div>
              </div>
            </div>
          </div> */}

          <div class="row row-content services services-offered-section">
            <div class="col-12  text-center" id="discount-block">
              <h2 class="mt-3">Services Offered</h2>
              <h5>Advika Edutech Core Services</h5>
              <div class="industry-grid mt-3">
                <div class="grid_row service-offered">
                  <div class="grid_item">
                    <div class="grid_img">
                      <img src={EcomServices} class="img-fluid" />
                    </div>
                    <span class="grid_text">Online Examination</span>
                    <p className="services-keywords">
                      Market Place Account Managment
                      <br />
                      Business Managment of all vertical
                      <br />
                      Advisory to the board
                    </p>
                    <Link to="/ecommerce-account-management-services">
                      <div class="overlay">
                        <div class="text">
                          <p className="mb-3">Online Examination</p>
                          <p>
                            Advika Edutech is your premier partner for advanced
                            Education and Examination Management Solutions. Our
                            secure Exam Management and Online Examination
                            Software is made for tailored service Exams like
                            Indian Judicial Services Exams. In the Era of
                            Digital Innovations, Conducting and taking
                            Examinations is such a task as It needs to ensure
                            Integrity, Security and Efficiency. Advika Edutech
                            provides you with the most secure examination
                            management system, which has advanced features.
                          </p>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div class="grid_item">
                    <div class="grid_img">
                      <img src={HrServices} class="img-fluid" />
                    </div>
                    <span class="grid_text">IT Infrastructure</span>
                    <p className="services-keywords">
                      Employee Performance Improvisation
                      <br />
                      Employee KRA &amp; KPIs Formulation
                      <br />
                      Recruitment Mid &amp; Senior Level
                    </p>
                    <Link to="/hr-consultancy-services">
                      <div class="overlay">
                        <div class="text">
                          <p className="mb-3">IT Infrastructure </p>
                          <p>
                            In this generation, Integrity and Efficiency are
                            paramount and we stand out to provide holistic
                            solutions. We offer you offline and online exam
                            facilities. We offer you seamless exam venues and
                            Advanced IT Infrastructure Management. Advika
                            Edutech provides meticulous exam venue management
                            services to ensure a smooth examination process. Our
                            Advanced IT Infrastructure supports online and
                            offline exams which includes secure networks,
                            reliable data storage solutions, and Efficient Exam
                            Venue Management selecting, setting up, and managing
                            exam venues.
                          </p>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div class="grid_item">
                    <div class="grid_img">
                      <img src={Consulting} class="img-fluid" />
                    </div>
                    <span class="grid_text">IT Consulting</span>
                    <p className="services-keywords">
                      Annual &amp; Quarterly Business Planning
                      <br />
                      Driving Sales &amp; Marketing Team
                      <br />
                      Efficiency Optimisation
                    </p>
                    <Link to="/ecommerce-business-management-company">
                      <div class="overlay">
                        <div class="text">
                          <p className="mb-3">IT Consulting</p>
                          <p>
                            Advika Edutech also provides a wide range of IT
                            Consulting services to support education institutes,
                            corporations, and government bodies in achieving
                            their technological and operational goals. We are a
                            tech support service provider to ensure IT
                            Infrastructure runs smoothly and efficiently. We
                            offer prompt and reliable support to address issues
                            and minimize downtime and productivity. We want to
                            become the leading customer support outsourcing
                            company in India,
                          </p>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div class="grid_item">
                    <div class="grid_img">
                      <img src={AlliedServices} class="img-fluid" />
                    </div>
                    <span class="grid_text">Manpower Staffing</span>
                    <p className="services-keywords">
                      Initiating New Business Avenues
                      <br />
                      Channel Partners Appointment
                      <br />
                      Marketing Services
                    </p>

                    <Link to="/mid-level-management-recruitment-consultants">
                      <div class="overlay">
                        <div class="text">
                          <p className="mb-3">Manpower Staffing</p>
                          <p>
                            As Advika Edutech provides you with all exam-related
                            services, we are committed to providing you with
                            exceptional manpower staffing solutions to meet all
                            your needs at one stop. Our expertise in this domain
                            will ensure you fulfil all your requirements as we
                            specialize in offering overseas manpower consultancy
                            and act as a reliable manpower supply agency
                            ensuring you achieve your objectives. We excel as an
                            overseas manpower consultancy, bridging the gap
                            between talent and organizations needing specialized
                            skills.
                          </p>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row row-content fitplans choose-us-section">
            <div class="col-12 text-center p-2" id="discount-block">
              <h2>Why Choose Us As Your Business Solutions Provider?</h2>
              <h5>
                Advika Edutech is the best Online Examination Service Provider
                in India that provides you
              </h5>
            </div>

            {/* <div class="col-sm-6 deals my-auto" id="reservebtn">
              <div>
                <h3 class="">Affordable Services </h3>
                <p class="mt-4">
                  Our clients come back to us for quality e-commerce services at
                  the lowest prices. We provide pocket-friendly solutions to our
                  clients depending upon their business needs.
                </p>
              </div>
              <div>
                <h3 class="">Competitive Expert Team</h3>
                <p class="mt-4">
                  Lebenswerk Consulting is a team of qualified professionals who
                  have years of practical expertise in the e-commerce industry.
                  Our highly experienced executives possess advanced technical
                  and industrial knowledge.
                </p>
              </div>
              <div>
                <h3 class="">Integration With Trusted Partners </h3>
                <p class="mt-4">
                  We have maintained healthy business relations with verified
                  and trusted partners who fulfill their commitment because when
                  you trust us for your business, we leave no room for doubts.
                </p>
              </div>
            </div>

            <div class="col-sm-6 p-2">
              <div class="row">
                <div class="col p-5">
                  {" "}
                  <img src={LebenswerkRetail} class="img-fluid" />
                </div>
              </div>
            </div> */}
            <br />

            <div class="col-sm-6 p-2">
              <div class="row d-sm-block">
                <div class="col p-5">
                  <img src={techsupport} alt="tech support" class="img-fluid" />
                </div>
              </div>
            </div>
            <div class="col-sm-6 deals my-auto" id="reservebtn">
              <div>
                <h3 class="">High-End Tech Support</h3>
                <p class="mt-4">
                  The e-commerce arena is filled with technicalities that’s why
                  we utilize the best and trusted industry tools and softwares
                  so that you can get reliable results.
                </p>
              </div>
              <div>
                <h3 class="">Exceptional Chat & Call Support</h3>
                <p class="mt-4">
                  We understand that dealing in the e-commerce business requires
                  uninterrupted availability. That’s why we provide seamless B2B
                  and B2C chat and call support to our clients.
                </p>
              </div>
              <div>
                <h3 class="">Affordable Services </h3>
                <p class="mt-4">
                  Our clients come back to us for quality e-commerce services at
                  the lowest prices. We provide pocket-friendly solutions to our
                  clients depending upon their business needs.
                </p>
              </div>
              <div>
                <h3 class="">Competitive Expert Team</h3>
                <p class="mt-4">
                  Advika Edutech is a team of qualified professionals who have
                  years of practical expertise in the e-commerce industry. Our
                  highly experienced executives possess advanced technical and
                  industrial knowledge.
                </p>
              </div>
              <div>
                <h3 class="">Integration With Trusted Partners </h3>
                <p class="mt-4">
                  We have maintained healthy business relations with verified
                  and trusted partners who fulfill their commitment because when
                  you trust us for your business, we leave no room for doubts.
                </p>
              </div>
            </div>

            {/* <div class="row  d-block d-sm-none">
              <div class="col p-5">
                {" "}
                <img src={LebenswerkFmcg} class="img-fluid" />
              </div>
            </div> */}
          </div>

          {/* <div class="row row-content fitplans">
            <div class="col-12 text-center p-2" id="discount-block">
              <h2>Brand Exposure</h2>
            </div>

            <div
              class="col-sm-12 p-3 pb-5 text-center deals bbottom"
              id="reservebtn"
            >
              <h5>Fashion Category</h5>
              <div class="d-flex flex-wrap justify-content-center ">
                <div class="p-2 my-auto bd-highlight">
                  <img src={relaxo} class="img-fluid" />
                </div>
                <div class="p-2 my-auto bd-highlight">
                  <img src={sparx} class="img-fluid" />
                </div>
                <div class=" my-auto bd-highlight">
                  <img src={lawman} class="img-fluid" />
                </div>
                <div class="p-2 bd-highlight">
                  <img src={nike} class="img-fluid" />
                </div>
                <div class="p-2 my-auto bd-highlight">
                  <img src={fila} class="img-fluid" />
                </div>
                <div class="p-2 my-auto bd-highlight">
                  <img src={killer} class="img-fluid" />
                </div>
                <div class="p-2 bd-highlight">
                  <img src={campus} class="img-fluid" />
                </div>

                <div class="p-2 bd-highlight">
                  <img src={aqualite} class="img-fluid" />
                </div>
                <div class="p-2 bd-highlight">
                  <img src={lotto} class="img-fluid" />
                </div>
                <div class="p-2 my-auto bd-highlight">
                  <img src={flite} class="img-fluid" />
                </div>
                <div class="p-2 my-auto bd-highlight">
                  <img src={bahamas} class="img-fluid" />
                </div>
                <div class="p-2 my-auto bd-highlight">
                  <img src={liberty} class="img-fluid" />
                </div>
                <div class="p-2 bd-highlight">
                  <img src={RedChief} class="img-fluid" />
                </div>
                <div class="p-2 bd-highlight">
                  <img src={provogue} class="img-fluid" />
                </div>
                <div class="p-2 bd-highlight">
                  <img src={combit} class="img-fluid" />
                </div>
                <div class="p-2 bd-highlight">
                  <img src={champs} class="img-fluid" />
                </div>

                <div class="p-2 bd-highlight">
                  <img src={action} class="img-fluid" />
                </div>
                <div class="p-2 my-auto bd-highlight">
                  <img src={furo} class="img-fluid" />
                </div>
                <div class="p-2 my-auto bd-highlight">
                  <img src={styleisland} alt="styleisland" class="img-fluid" />
                </div>
                <div class="p-2 my-auto bd-highlight">
                  <img src={homeco} alt="homeco" class="img-fluid" />
                </div>
                <div class="p-2 my-auto bd-highlight">
                  <img
                    src={draaxfashion}
                    alt="draaxfashion"
                    class="img-fluid"
                  />
                </div>
                <div class="p-2 my-auto bd-highlight">
                  <img src={khwaabi} alt="khwaabi" class="img-fluid" />
                </div>
                <div class="p-2 my-auto bd-highlight">
                  <img src={tatters} alt="tatters" class="img-fluid" />
                </div>
                <div class="p-2 my-auto bd-highlight">
                  <img src={devpharma} alt="devpharma" class="img-fluid" />
                </div>
                <div class="p-2 my-auto bd-highlight">
                  <img src={metalsmith} alt="metalsmith" class="img-fluid" />
                </div>
                <div class="p-2 my-auto bd-highlight">
                  <img src={phoebee} alt="phoebee" class="img-fluid" />
                </div>
                <div class="p-2 my-auto bd-highlight">
                  <img src={tibolli} alt="tibolli" class="img-fluid" />
                </div>
                <div class="p-2 my-auto bd-highlight">
                  <img src={hilary} alt="hilary" class="img-fluid" />
                </div>
                <div class="p-2 my-auto bd-highlight">
                  <img src={phlogo} alt="ph" class="img-fluid" />
                </div>
                <div class="p-2 my-auto bd-highlight">
                  <img src={swissbeauty} alt="swissbeauty" class="img-fluid" />
                </div>
                <div class="p-2 my-auto bd-highlight">
                  <img src={gkhair} alt="gkhair" class="img-fluid" />
                </div>
                <div class="p-2 my-auto bd-highlight">
                  <img src={keune} alt="keune" class="img-fluid" />
                </div>
              </div>
            </div>

            <div class="col-sm-6 p-3 pt-5 text-center deals">
              <h5>FMCG Category</h5>
              <div class="d-flex flex-wrap justify-content-center">
                <div class="p-2 my-auto bd-highlight">
                  <img src={cadbury} class="img-fluid" />
                </div>
                <div class="p-2 my-auto bd-highlight">
                  <img src={brucoffeelogo} class="img-fluid" />
                </div>
                <div class="p-2 my-auto bd-highlight">
                  <img src={unilever} class="img-fluid" />
                </div>
                <div class="p-2 my-auto bd-highlight">
                  <img src={pepsi} class="img-fluid" />
                </div>
                <div class="p-2 my-auto bd-highlight">
                  <img src={lipton} class="img-fluid" />
                </div>
                <div class="p-2 my-auto bd-highlight">
                  <img src={tajmahal} class="img-fluid" />
                </div>
                <div class="p-2 my-auto bd-highlight">
                  <img src={sugarfree} class="img-fluid" />
                </div>
                <div class="p-2 my-auto bd-highlight">
                  <img src={everyouth} class="img-fluid" />
                </div>
              </div>
            </div>

            <div class="col-sm-6 p-3 pt-5 text-center deals">
              <h5>Stationary Category</h5>
              <div class="d-flex flex-wrap justify-content-center">
                <div class="p-2 my-auto bd-highlight">
                  <img src={Luxor} class="img-fluid" />
                </div>
                <div class="p-2 my-auto bd-highlight">
                  <img src={Parker} class="img-fluid" />
                </div>
                <div class="p-2 my-auto bd-highlight">
                  <img src={waterman} class="img-fluid" />
                </div>
                <div class="p-2 my-auto bd-highlight">
                  <img src={pilot} class="img-fluid" />
                </div>
                <div class="d-flex flex-wrap pt-4 justify-content-center">
                  <div class="p-2  my-auto bd-highlight">
                    {" "}
                    <h5 class="tel">OTHER CATEGORIES:</h5>{" "}
                  </div>
                  <div class="p-2 my-auto bd-highlight">
                    <img src={vodafone} class="img-fluid" />
                  </div>
                  <div class="p-2 my-auto bd-highlight">
                    <img src={shell} class="img-fluid" />
                  </div>
                  <div class="p-2 my-auto bd-highlight">
                    <img src={valvoline} class="img-fluid" />
                  </div>
                </div>
              </div>
            </div>
            <br />
          </div> */}

          <div class="row row-content domain-expert">
            <div class="col-12 text-center p-2 py-3" id="discount-block">
              <h2>Domain Expertise</h2>
              <h5>Discover the key features from Advika Edutech</h5>
            </div>

            <div class="col-12 py-2">
              <div class="domain-grid">
                <div class="grid_row" id="main-container">
                  <div class="grid_item" id="test-box">
                    <div class="grid_img">
                      <img src={sale} class="img-fluid" />
                    </div>
                    <span class="grid_text">Channel Sales</span>
                  </div>
                  <div class="grid_item" id="test-box">
                    <div class="grid_img">
                      <img src={cart} class="img-fluid" />
                    </div>
                    <span class="grid_text"> eCommerce – B2C &amp; B2B </span>
                  </div>
                  <div class="grid_item" id="test-box">
                    <div class="grid_img">
                      <img src={moderntrade} class="img-fluid" />
                    </div>
                    <span class="grid_text">Modern Trade </span>
                  </div>
                  <div class="grid_item" id="test-box">
                    <div class="grid_img">
                      <img src={largeformatretail} class="img-fluid" />
                    </div>
                    <span class="grid_text">Large Format Retail</span>
                  </div>
                </div>
                <div class="grid_row" id="main-container">
                  <div class="grid_item">
                    <div class="grid_img">
                      <img src={brand} class="img-fluid" />
                    </div>
                    <span class="grid_text">Multi Brand Outlets</span>
                  </div>
                  <div class="grid_item">
                    <div class="grid_img">
                      <img src={wholesale} class="img-fluid" />
                    </div>
                    <span class="grid_text">Wholesale</span>
                  </div>
                  <div class="grid_item">
                    <div class="grid_img">
                      <img src={store} class="img-fluid" />
                    </div>
                    <span class="grid_text">Institutions </span>
                  </div>
                  <div class="grid_item">
                    <div class="grid_img">
                      <img src={giftbox} class="img-fluid" />
                    </div>
                    <span class="grid_text">Corporate Gifting</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-12 text-center pb-2 bounce">
              <a
                data-toggle="collapse"
                href="#domainExpertise"
                aria-expanded="false"
                aria-controls="domainExpertise"
              >
                <i class="fa fa-angle-double-down" aria-hidden="true"></i>
              </a>
              <div class="collapse" id="domainExpertise">
                <div class="card card-body">
                  <div class="py-3 ">
                    <img src={domainImg} class="img-fluid" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row row-content contact">
            <div
              class="col-12 text-center px-5 align-items-center"
              id="whatsapp-block"
            >
              <h2>Feel Free to Contact Us 24x7 → &nbsp;</h2>
              {/*      
    <a href="mailto:info@lebenswerk.in"> <i class="fas fa-envelope"></i>
                      */}
              <a
                href="https://api.whatsapp.com/send?phone=919971222456"
                class="btn btn-lg whatspp-head"
                role="button"
              >
                <FontAwesomeIcon icon={faWhatsapp} className="whatspp-head" />
              </a>
              <a href="tel:09971222456" class="btn btn-lg " role="button">
                <FontAwesomeIcon icon={faPhone} />
              </a>
              &nbsp;
              <img src={Contact} class="img-fluid" />
            </div>
          </div>
          <div class="row row-content services services-offered-section">
            <div class="col-12  text-center" id="discount-block">
              <h2 class="mt-3">Certificates</h2>
              <h5>Advika Edutech Core Services</h5>
              <div class="industry-grid mt-3">
                <div class="grid_row service-offered">
                  <div class="grid_item certificates ">
                    <iframe
                      class="pdf"
                      src={ADVIKA_pdf2}
                      width="800"
                      height="500"
                    ></iframe>
                  </div>
                  <div class="grid_item certificates">
                    <iframe
                      class="pdf"
                      src={ADVIKA_pdf3}
                      width="800"
                      height="500"
                    ></iframe>
                  </div>
                  <div class="grid_item certificates">
                    <iframe
                      class="pdf"
                      src={ADVIKA_pdf1}
                      width="800"
                      height="500"
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div class="row row-content propServices">
            <div class="text-center container">
              <div class="row mb-2">
                <div class="col text-center" id="discount-block">
                  <h1> Key Account Management</h1>
                  <p class="lead">Trusted by</p>
                </div>
              </div>
              <div class="py-4 row d-flex align-items-center">
                <div class="container">
                  <SliderComp />
                </div>
              </div>
            </div>

            <div class="row row-content lfr">
              <div
                class="col-sm-12 p-3 pb-2 text-center deals "
                id="reservebtn"
              >
                <h1>Large Format Retail</h1>
                <div class="d-flex flex-wrap justify-content-center ">
                  <div class="p-2 my-auto bd-highlight">
                    <img src={dmart} class="img-fluid" />
                  </div>
                  <div class="p-2 my-auto bd-highlight">
                    <img src={walmart} class="img-fluid" />
                  </div>
                  <div class=" my-auto bd-highlight">
                    <img src={bigbazaar} class="img-fluid" />
                  </div>
                  <div class="p-2 my-auto bd-highlight">
                    <img src={metroshoes} class="img-fluid" />
                  </div>
                  <div class="p-2 my-auto bd-highlight">
                    <img src={metrocash2} class="img-fluid" />
                  </div>
                  <div class="my-auto  bd-highlight">
                    <img src={itc} class="img-fluid" />
                  </div>
                  <div class="p-2 my-auto  bd-highlight">
                    <img src={abrl} class="img-fluid" />
                  </div>
                  <div class="my-auto  bd-highlight">
                    <img src={Saravana} class="img-fluid" />
                  </div>

                  <div class="p-2 bd-highlight">
                    <img src={lifestyle} class="img-fluid" />
                  </div>

                  <div class="p-2 my-auto bd-highlight">
                    <img src={vishal} class="img-fluid" />
                  </div>
                </div>
              </div>

              <br />
            </div>
            <div class="row row-content ins">
              <div
                class="col-12 text-center Institutions pt-3"
                id="discount-block"
              >
                <h3>Institutions</h3>
                <div class="d-flex flex-wrap justify-content-center ">
                  <div class="p-2 my-auto bd-highlight">
                    <h6>CPC</h6>
                  </div>
                  <div class="p-2 my-auto bd-highlight">
                    <h6>Gifting Solutions</h6>
                  </div>
                  <div class="p-2 my-auto bd-highlight lastelem">
                    <h6>CSD</h6>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          <div class="row row-content domain-expert faq-section">
            <div class="col-12 text-center p-2 py-3" id="discount-block">
              <h2>Frequently Asked Questions (FAQs)</h2>
              <h5>Discover the key features from Advika Edutech</h5>
            </div>

            <div class="col-12 py-2">
              {/* <div class="faq-grid">
                <div class="grid_row" id="main-container">
                  <div class="grid_item" id="test-box">
                    <span class="grid_text">
                      How Are E-Commerce Solutions Important?
                    </span>
                    <div class="grid_img">
                      <img src={sale} class="img-fluid" />
                    </div>
                  </div>
                </div>
                <div class="grid_row" id="main-container">
                  <div class="grid_item">
                    <span class="grid_text">Multi Brand Outlets</span>
                    <div class="grid_img">
                      <img src={brand} class="img-fluid" />
                    </div>
                  </div>
                </div>
                <div class="grid_row" id="main-container">
                  <div class="grid_item" id="test-box">
                    <span class="grid_text">Channel Sales</span>
                    <div class="grid_img">
                      <img src={sale} class="img-fluid" />
                    </div>
                  </div>
                </div>
                <div class="grid_row" id="main-container">
                  <div class="grid_item">
                    <span class="grid_text">Multi Brand Outlets</span>
                    <div class="grid_img">
                      <img src={brand} class="img-fluid" />
                    </div>
                  </div>
                </div>
                <div class="grid_row" id="main-container">
                  <div class="grid_item" id="test-box">
                    <span class="grid_text">Channel Sales</span>
                    <div class="grid_img">
                      <img src={sale} class="img-fluid" />
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="faq-grid">
                {accordionData.map(({ title, content }) => (
                  <Accordion title={title} content={content} />
                ))}
              </div>
            </div>
          </div>

          <div id="JoinModal" class="modal fade" role="dialog">
            <div class="modal-dialog modal-lg" role="content">
              <div class="modal-content">
                <div class="modal-header bg-secondary">
                  <h4 class="modal-title text-white">Membership Details </h4>
                  <button type="button" class="close" data-dismiss="modal">
                    ×
                  </button>
                </div>
                <div class="card-body">
                  <table class="table table-dark table-striped">
                    <thead>
                      <tr>
                        <th>Plan</th>
                        <th>Offer Price</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Monthly</td>
                        <td>700 Rs-/</td>
                      </tr>
                      <tr>
                        <td>3 Months</td>
                        <td>1800 Rs-/</td>
                      </tr>
                      <tr>
                        <td>6 Months</td>
                        <td>3800 Rs-/</td>
                      </tr>
                      <tr>
                        <td>Yearly</td>
                        <td>7000 Rs-/</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Home;
